exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-donors-js": () => import("./../../../src/pages/donors.js" /* webpackChunkName: "component---src-pages-donors-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/downloadApp.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-go-to-app-js": () => import("./../../../src/pages/goToApp.js" /* webpackChunkName: "component---src-pages-go-to-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-project-[id]-js": () => import("./../../../src/pages/project/[id].js" /* webpackChunkName: "component---src-pages-project-[id]-js" */),
  "component---src-pages-project-creation-[type]-js": () => import("./../../../src/pages/project/creation/[type].js" /* webpackChunkName: "component---src-pages-project-creation-[type]-js" */),
  "component---src-pages-project-select-project-js": () => import("./../../../src/pages/project/select-project.js" /* webpackChunkName: "component---src-pages-project-select-project-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-solicitants-js": () => import("./../../../src/pages/solicitants.js" /* webpackChunkName: "component---src-pages-solicitants-js" */)
}

